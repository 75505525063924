import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@material-ui/core";
import {days} from "../../../Settings/OfficeTimings/tools";
import {KeyboardTimePicker} from "@material-ui/pickers";
import React, {useEffect, useState} from "react";


const useStyles = makeStyles(theme => ({
    questionBox: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingBottom: theme.spacing(2),
    },
    selectors: {
        justifyContent: "end",
    },
    daysSelector: {
        textAlign: "center",
    },
    buttonContainer: {
        marginTop: theme.spacing(3),
        justifyContent: "center",
    }
}));


export default function AddNewWeekdayRule(props) {
    const initialRuleData = {
        holiday: props.holiday.id,
        fixed_start_time: '16:00',
        days_before: 0,
        fixed_end_time: props.defaultRule.fixed_end_time,
        minutes_before: 0,
        minutes_after: props.defaultRule.minutes_after,
        holiday_weekday: null,
        respond_weekday: null,
    };
    const croppedHolidayName = props.croppedName ? props.croppedName : props.holiday.holiday_name.split('/')[0];
    const classes = useStyles();

    const [holidayStartDate] = useState(new Date());
    const [startOption, setStartOption] = useState('fixed');
    const [fixedStartTime, setFixedStartTime] = useState(initialRuleData.fixed_start_time.split(":")); // first item - hours, second - min
    const [minBeforeSundown, setMinBeforeSundown] = useState(0);
    const [holidayRuleData, setHolidayRuleData] = useState(initialRuleData);
    const [isValid, setIsValid] = useState(true);
    const setValuesToRule = values => setHolidayRuleData({...holidayRuleData, ...values});

    const handleStartOptionChange = (event) => {
        setStartOption(event.target.value);
    };
    
    useEffect(() => {
        setHolidayRuleData({
            ...holidayRuleData,
            fixed_end_time: initialRuleData.fixed_end_time,
            minutes_after: initialRuleData.minutes_after,
        });
    }, [props.defaultRule]);

    const handleMinBeforeSundown = (event) => {
        let minutes = event.target.value;
        if (minutes < 0) {
            setMinBeforeSundown(0);
        } else if (minutes > 120) {
            setMinBeforeSundown(120);
        } else {
            setMinBeforeSundown(minutes);
        }
    };

    const handleDateChange = (date) => {
        setFixedStartTime([date.getHours(), date.getMinutes()]);
    };

    const resetFields = () => {
        setFixedStartTime([16 , 0]);
        setMinBeforeSundown(0);
        setHolidayRuleData(initialRuleData);
        setIsValid(true);
    };

    const addRule = () => {
        if (holidayRuleData.holiday_weekday && holidayRuleData.respond_weekday){
            props.addNewRule(holidayRuleData);
            resetFields();
        } else (
            setIsValid(false)
        );
    };

    useEffect(() => {
        holidayStartDate.setHours(fixedStartTime[0], fixedStartTime[1]);
        if(startOption === 'fixed'){
            setValuesToRule({fixed_start_time: fixedStartTime.join(':'), minutes_before: null});
        } else {
            setValuesToRule({minutes_before: parseInt(minBeforeSundown), fixed_start_time: null});
        }
    }, [startOption, fixedStartTime, minBeforeSundown]);

    return(
        <div className={classes.questionBox}>
            <Grid container direction="row" justifyContent="center" alignItems="flex-end">
                <Grid item xs={4}>
                    <Typography>
                        If {croppedHolidayName} is on:
                    </Typography>
                </Grid>
                <Grid item xs={2} className={classes.daysSelector}>
                    <FormControl style={{minWidth: '120px'}} margin="dense" required error={!isValid}>
                        <InputLabel id="select-day-label">Day</InputLabel>
                        <Select value={holidayRuleData.holiday_weekday ? holidayRuleData.holiday_weekday : ""}
                                labelId="select-day-label"
                                onChange={e => setValuesToRule({holiday_weekday: e.target.value})}>
                            {days.map(item => <MenuItem
                                key={item.value}
                                value={item.value}>{item.label}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="center" alignItems="flex-end">
                <Grid item xs={4}>
                    <Typography>
                        Then the Autoresponder should start on:
                    </Typography>
                </Grid>
                <Grid item xs={2} className={classes.daysSelector}>
                    <FormControl style={{minWidth: '120px'}} margin="dense" required error={!isValid}>
                        <InputLabel id="select-day-label">Day</InputLabel>
                        <Select value={holidayRuleData.respond_weekday ? holidayRuleData.respond_weekday : ""}
                                labelId="select-day-label"
                                onChange={e => setValuesToRule({respond_weekday: e.target.value})}>
                            {days.map(item => <MenuItem
                                key={item.value}
                                value={item.value}>{item.label}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm container spacing={3} className={classes.selectors}>
                    <Grid item xs={5}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Start option</InputLabel>
                            <Select
                                value={startOption}
                                label="Start time option"
                                onChange={handleStartOptionChange}
                            >
                                <MenuItem value={'fixed'}>Fixed time start</MenuItem>
                                <MenuItem value={'minutes'}>Minutes before sundown</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={5}>
                        {startOption === 'fixed' ? (
                            <KeyboardTimePicker
                                label="Fixed start"
                                value={holidayStartDate}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change time',
                                }}
                            />
                        ) : (
                            <TextField
                                required
                                label= "Minutes before sundown"
                                fullWidth
                                type="number"
                                onChange={handleMinBeforeSundown}
                                value={minBeforeSundown}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                className={classes.buttonContainer}
            >
                <Grid item>
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={addRule}
                    >
                        Add
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}